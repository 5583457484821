import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import NewsTicker from "../services/NewsTicker";
import EditableContent from "./admin/editability";


function HomePageComp() {
    const [token, setToken] = useState("")
    const myRef = useRef(null);
    const [divID, setDivID] = useState("")
    const [isEditing, setIsEditing] = useState(false)
    const [currentContent, setCurrentContent] = useState("")
    useEffect(() => {
        window.scrollTo(0, 0)
        setToken(localStorage.getItem('token'))
    }, [])
    const edit = (event) => {
        setDivID(event.id)
        setIsEditing(true)
        setCurrentContent(document.getElementById(event.id).textContent)
    }
        return (
            <div id={"homePage"} style={{marginBottom: "25px"}}>
                <div style={{marginTop: "50px"}}>
                        <h6>This research website is under the management of Prof. Pinhas Alpert and Prof. Nili Harnik,
                            for the purpose of atmospheric and environmental data access.</h6>
                    <hr style={{marginLeft: "70px", marginRight: "70px"}}/>
                    <hr style={{marginLeft: "70px", marginRight: "70px"}}/>
                </div>
                <div className={"flex-child-element:first-child"} align={"left"}
                     style={{float: "right", width: "27%", marginTop: "30px", marginRight: "35px"}}>
                    <div>
                        <h3> Datasets for download</h3>
                        <h5>Datasets by name</h5>
                        <Link to={"/data_access"} className={'button'} style={{marginBottom: "10px"}}>Datasets</Link>
                        <br/>
                        <br/>
                        <Link to={"/categories"} className={'button'} style={{marginBottom: "10px"}}>Categories</Link>
                        <br/>
                        <br/>
                    </div>
                    <div>
                        <NewsTicker/>
                    </div>
                    <div style={{marginTop: "200px"}}>
                        <a href={"https://english.tau.ac.il/"} target={"_blank"} rel={"noreferrer"}>
                            <img src={"https://english.tau.ac.il/sites/default/files/TAU_Logo_HomePage_Eng.png"}
                                 alt={"TAU"}
                                 style={{height: "160px", width: "300px"}}/>
                        </a>
                        <br/>
                        <a href={"https://www.gov.il/he/departments/ministry_of_science_and_technology/govil-landing-page"}
                           target={"_blank"} rel={"noreferrer"}>
                            <img src={"https://upload.wikimedia.org/wikipedia/he/6/6f/MIST_logo.jpg"}
                                 alt={"Science Ministry"}
                                 style={{height: "130px", width: "300px", marginTop: "75px"}}/>
                        </a>
                    </div>
                </div>
                <div className={"flex-child-element"} align={"left"}
                     style={{marginLeft: "50px", marginTop: "30px", marginBottom: "30px", width: "60%"}}>
                    <div id={"body"}>
                        <input type={"button"} className={token ? 'button' : 'noButton'} onClick={() => {
                            edit(myRef.current)
                        }} value={"edit"}>
                        </input>
                        {isEditing ?
                            <EditableContent contentKey={divID} currentContent={currentContent} contentType={"text"}
                                             editing={true}/>
                            :
                            <h2>About Us</h2>
                        }
                        <br/>
                        {isEditing ?
                            <EditableContent contentKey={divID} currentContent={currentContent} contentType={"text"}
                                             editing={true}/>
                            :
                            <p ref={myRef} id={"0"}>The Israeli Atmospheric and Climatic Data Center (IACDC),
                                provides easy access to geophysical data from Israel and across the globe.
                                This includes data from climate models and reanalyses, historical climate and weather
                                reports on averages and trends, as well as station measurements such as rainfall and
                                temperature data. You can find a list of this data on our <Link to="/data_access">datasets
                                    page</Link>.</p>}
                        <input type={"button"} className={token ? 'button' : 'noButton'} onClick={() => {
                            edit(myRef.current)
                        }} value={"edit"}>
                        </input>
                        {isEditing ?
                            <EditableContent contentKey={divID} currentContent={currentContent} contentType={"text"}
                                             editing={true}/>
                            :
                            <p ref={myRef} id={"1"}>The IACDC is funded by the
                                <a href={"https://www.gov.il/he/departments/ministry_of_science_and_technology/govil-landing-page"}
                                   target={"_blank"} rel={"noreferrer"}> Israel Ministry of Science, Technology and
                                    Space </a>
                                to relieve researchers of part of the time and effort associated with finding and
                                accessing atmospheric and climate data from projects across the globe,
                                by collecting and allowing access from one place.</p>}
                        <input type={"button"} className={token ? 'button' : 'noButton'} onClick={() => {
                            edit(myRef.current)
                        }} value={"edit"}>
                        </input>
                        {isEditing ?
                            <EditableContent contentKey={divID} currentContent={currentContent} contentType={"text"}
                                             editing={true}/>
                            :
                            <p>Having launched in November 2017, we are currently growing and wish to cater
                                to as wide an audience as possible, downloading large amounts of data and
                                expanding the datasets we make available. We are interested in your feedback,
                                as to what datasets would serve you and researchers in your field of study.
                                You’re invited to get in touch through or contact form at&nbsp;
                                <Link to={"contact"}>contact us</Link>.</p>}
                        {isEditing ?
                            <EditableContent contentKey={divID} currentContent={currentContent} contentType={"text"}
                                             editing={true}/>
                            :
                            <p>More information about the IACDC can be found <Link to={"about"}>here</Link>.</p>
                        }
                    </div>
                    <div>
                        <h2>What we do</h2>
                        <p>At the IACDC we aim to facilitate the access and use of a
                            multitude of datasets through the standardized archiving of geophysical data.
                            Various datasets, such as climate model output, reanalyses, satellite data,
                            and raw measurements, will be archived using the Obs4MIPS and CMIP5 archiving standard;
                            thereby relieving researchers of much of the work associated with downloading and
                            converting between data formats, as well as allowing them to employ uniform
                            analysis methods across datasets.</p>
                        <p>We also dedicate a special effort a special effort to collect data unique to Israel
                            such as output from regional models, historical reports, and other measurements,
                            unavailable elsewhere.</p>
                        <p>We are also happy to offer researchers the ability to “order” data
                            they require from the center, and have it temporarily stored with us.
                            And additionally aid in creating a “community data sharing” model,
                            connecting between researchers with access to different datasets,
                            to maximise the volume of data accessible to researchers in the field.</p>
                        <p>The center is currently in the process of being built.
                            We currently have access to numerous datasets in our data access page and are
                            able to download other datasets or variables upon request.
                            At a later stage we also aim is to provide the ability to perform additional tasks,
                            such as subsetting and calculation of derived quantities upon request.</p>
                    </div>
                    <div style={{marginTop: "50px"}}>
                        <h3>Acknowledgements</h3>
                        <p>Datasets should be properly cited. Each dataset’s page contains a short
                            description on how to cite it, or a link to a page containing this information.</p>
                        <p>In addition, we ask that work in which datasets have been obtained through
                            the IACDC also include an acknowledgement along the lines of the example given below:</p>
                        <p>I/we acknowledge the Israeli Atmospheric and Climatic Data Centre,
                            supported by the Israeli Ministry of Science, Technology and Space,
                            for providing the [DATASET] data used.</p>
                    </div>
                </div>
            </div>
        )
}

export default HomePageComp