import React, { useState, useEffect } from 'react';
//import Utils from "../../utils/Utils";

function EditableContent(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(props.currentContent);

  useEffect(() => {
    setContent(props.currentContent);// Reset content when currentContent changes
    setIsEditing(props.editing)
  }, [props.editing, props.currentContent]);

  const handleSave = () => {
    //Utils.editPage(contentKey, content);  // Save the content to the backend or state
    setIsEditing(false);
  };

  return (
    <div>
      {isEditing ? (
        <>
          {props.contentType === 'text' ? (
            <textarea style={{width:"80%", height:"fit-content"}}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          ) : (
            <input
              type="text"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          )}
          <button onClick={handleSave}>Save</button>
          <button onClick={() => setIsEditing(false)}>Cancel</button>
        </>
      ) : (
        <div onClick={() => setIsEditing(true)}>
          {content || <em>Click to edit</em>}1
        </div>
      )}
    </div>
  );
}

export default EditableContent;
